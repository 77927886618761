export const etET = {
  Settings: 'Seaded',
  Save: 'Salvesta',
  'Login Page': 'Sisselogimisleht',
  Reset: 'Lähtesta',
  'Main Text': 'Põhitekst',
  'Secondary Text': 'Teisene tekst',
  Image: 'Pilt',
  'Recommended parameters': 'Soovitatavad parameetrid',
  Resolution: 'Resolutsioon',
  Proportions: 'Proportsioonid',
  'Site header': 'Saidi päis',
  Logo: 'Logo',
  'SMTP Information': 'SMTP teave',
  Host: 'Host',
  Port: 'Port',
  Login: 'Logi sisse',
  Password: 'Parool',
  'Forgot password': 'Unustasid parooli',
  'Restore password': 'Taasta parool',
  'Reset admin password': 'Lähtestage administraatori parool',
  'Back to login': 'Tagasi sisselogimise juurde',
  'Reset password': 'Lähtestage parool',
  Approved: 'Kinnitatud',
  Review: 'Ülevaade',
  'Not approved': 'Pole heaks kiidetud',
  'Updating cases, please wait': 'Juhtumite värskendamine, palun oodake',
  Search: 'Otsing',
  done_at: 'e-vorm lõpetati kell',
  created_at: 'Andmed on kättesaadavad aadressil',
  worker_name: 'e-vormi täitja',
  'Edit Case': 'Redigeeri juhtumit',
  'Delete Case': 'Kustuta juhtum',
  Download: 'Lae alla',
  'Are you sure you want to delete this case': 'Kas olete kindel, et soovite selle juhtumi kustutada?',
  'Accessible at': 'Juurdepääs aadressil',
  'Finished at': 'Lõpetas kl',
  'Done by': 'Teostatud',
  OK: 'Okei',
  Cancel: 'Tühista',
  'Edit Searchable item': 'Redigeeri otsitavat üksust',
  'Delete Searchable item': 'Kustuta otsitav üksus',
  'Edit searchable element': 'Muuda otsitavat elementi',
  Name: 'Nimi',
  Ok: 'Okei',
  'Import options (max 100 entries)': 'Impordivalikud (max 100 kirjet)',
  Import: 'Import',
  'My eForms': 'Minu e-vormid',
  'Create eForm': 'Looge e-vorm',
  'New eForm': 'Uus e-vorm',
  'New eForm from XML': 'Uus e-vorm XML-ist',
  Label: 'Silt',
  Tags: 'Sildid',
  CreatedAt: 'Loodud kell',
  'eForm Name': 'e-vormi nimi',
  Pairing: 'Sidumine',
  Actions: 'Tegevused',
  'Edit tags': 'Redigeeri silte',
  'Click to edit eForm pairing with user(s)': 'Klõpsake e-vormi kasutaja(te)ga sidumise muutmiseks',
  'Edit pairing': 'Muutke sidumist',
  'Click to pair eForm with user(s)': 'Klõpsake e-vormi sidumiseks kasutaja(te)ga',
  'Pair eForm': 'Siduge e-vorm',
  'Show cases': 'Näidake juhtumeid',
  'Edit columns': 'Redigeeri veerge',
  'Download CSV': 'Laadige alla CSV',
  'Delete eForm': 'Kustuta e-vorm',
  'Upload ZIP archive': 'Laadige üles ZIP-arhiiv',
  'Upload template ZIP': 'Laadi üles mall ZIP',
  'Uploading progress': 'Üleslaadimise edenemine',
  'Save eForm': 'Salvesta e-vorm',
  'Device user': 'Seadme kasutaja',
  'Check to pair': 'Sidumiseks märkige',
  'Save pairing': 'Salvesta sidumine',
  'Select columns for displaying in Cases': 'Valige juhtumites kuvamiseks veerud',
  Field: 'Väli',
  'Are you sure you want to delete': 'Kas soovite kindlasti kustutada?',
  'Creation Date': 'Loomise kuupäev',
  'Select tags for template': 'Valige malli jaoks sildid',
  'Template tags': 'Malli sildid',
  'New tag': 'Uus silt',
  'Tag list': 'Siltide loend',
  'Add field': 'Lisa väli',
  'Add sub eForm': 'Lisa e-vorm',
  'Edit sub eForm': 'Redigeeri e-vormi alamvormi',
  'New sub eForm': 'Uus alam e-vorm',
  'New field': 'Uus väli',
  'Field is not complete': 'Väli ei ole täidetud',
  'Add new nested field': 'Lisage väli rühma',
  'Copy field': 'Kopeeri väli',
  'Edit field': 'Muuda välja',
  'Delete field': 'Kustuta väli',
  'Expand field': 'Kuva rühmas olevad väljad',
  'Collapse field': 'Peida rühmas väljad',
  Mandatory: 'Kohustuslik',
  Type: 'Tüüp',
  'Are you sure you want to delete field': 'Kas olete kindel, et soovite välja kustutada?',
  'eForm is not complete': 'E-vorm ei ole täidetud',
  'Edit eForm': 'Muuda e-vormi',
  'New nested field': 'Uus pesastatud väli',
  Delete: 'Kustuta',
  'XML from eForm Builder': 'XML eForm Builderist',
  'Paste XML here': 'Kleebi XML siia',
  'Creation date': 'Loomise kuupäev',
  'Searchable lists': 'Otsitavad loendid',
  'Searchable list': 'Otsitav nimekiri',
  'Create new searchable list': 'Looge uus otsitav loend',
  'Edit site': 'Redigeeri saiti',
  'Selectable lists': 'Valitavad loendid',
  'Selectable list': 'Valitav nimekiri',
  'Create new selectable list': 'Loo uus valitav loend',
  'Terms of Service': 'Kasutustingimused',
  'Privacy Policy': 'Privaatsuspoliitika',
  'Data Protection Terms (GDPR)': 'Andmekaitsetingimused (GDPR)',
  'Save password': 'Salvesta parool',
  'Edit Device User': 'Redigeeri seadme kasutajat',
  'First name': 'Eesnimi',
  'Last name': 'Perekonnanimi',
  'Device Users': 'Seadme kasutajad',
  'Create Device User': 'Loo seadme kasutaja',
  'New Device User': 'Uus seadme kasutaja',
  'Site ID': 'Saidi ID',
  'Device ID': 'Seadme ID',
  Customer: 'Klient',
  'Customer no & OTP': 'Kliendi nr &amp; OTP',
  'New OTP': 'Uus OTP',
  'Delete Device User': 'Kustuta seadme kasutaja',
  WARNING: 'HOIATUS',
  'When pressing OK, you will deactivate the current device paired with this location': 'Kui vajutate nuppu OK, desaktiveerite praeguse selle asukohaga seotud seadme',
  'If there is data on the current device, this data will be lost after pressing OK': 'Kui praeguses seadmes on andmeid, lähevad need andmed pärast OK vajutamist kaotsi',
  Edit: 'Muuda',
  Sites: 'saidid',
  'Updated at': 'Uuendatud kell',
  'Are you sure you want to delete the site? The action can not be undone': 'Kas olete kindel, et soovite saidi kustutada? Toimingut ei saa tagasi võtta',
  Previous: 'Eelmine',
  Next: 'Edasi',
  Units: 'Ühikud',
  'Get OTP code': 'Hankige OTP-kood',
  'Activate OTP code for': 'Aktiveerige OTP-kood',
  'When pressing OK, you will deactivate the current unit assosiated with this location': 'Kui vajutate nuppu OK, desaktiveerite selle asukohaga seotud praeguse seadme',
  'You will se the new OTP code once you click OK': 'Kui klõpsate OK, näete uut OTP-koodi',
  Workers: 'Töölised',
  'Create Worker': 'Looge töötaja',
  'Edit Worker': 'Redigeeri töötajat',
  'Delete Worker': 'Kustuta töötaja',
  'Are you sure you want to delete the worker': 'Kas olete kindel, et soovite töötaja kustutada?',
  'Select Worker': 'Valige Töötaja',
  'Save Worker': 'Töötaja päästmine',
  Advanced: 'Admin',
  'Application Settings': 'Rakenduse seaded',
  'User management': 'Kasutajate haldamine',
  'Change password': 'Muuda salasõna',
  Logout: 'Logi välja',
  'Profile settings': 'Profiili seaded',
  Timezone: 'Ajavöönd',
  Language: 'Keel',
  'Force users to use 2-f-auth': 'Sundida kasutajaid kasutama 2-f-auth',
  'Old password': 'Vana parool',
  'New password': 'Uus parool (vähemalt 6 tähemärki)',
  'New password confirmation': 'Uue parooli kinnitus (vähemalt 6 tähemärki)',
  'Confirm new password': 'Kinnitage uus parool (vähemalt 6 tähemärki)',
  'Enter new password': 'Sisestage uus parool (vähemalt 6 tähemärki)',
  'Enter old password': 'Sisestage vana parool',
  'New User': 'Uus kasutaja',
  'Edit User': 'Redigeeri kasutajat',
  'Left empty if want to keep old': 'Jätke tühjaks, kui soovite vanaks jääda',
  Username: 'Kasutajanimi',
  Email: 'E-post',
  Status: 'Olek',
  'change will logout you from the application': 'muudatus logib teid rakendusest välja',
  'Remove google authenticator secret key': 'Eemaldage Google&#39;i autentija salavõti',
  'Remove PSK': 'Eemaldage PSK',
  'Full Name': 'Täisnimi',
  'Delete user': 'Kustuta kasutaja',
  'Open Google Authenticator and add Eform application by scanning the QR Code to the right': 'Avage Google Authenticator ja lisage rakendus Eform, skannides paremal asuva QR-koodi',
  'Enter the 6 digit code that Google Authenticator generates': 'Sisestage 6-kohaline kood, mille Google Authenticator genereerib',
  General: 'Kindral',
  Find: 'Otsi',
  'Nothing found': 'Ei leitud midagi',
  'Type in search box to see results': 'Tulemuste nägemiseks tippige otsingukasti',
  'Select tags for filter': 'Valige filtri jaoks sildid',
  'All selected': 'Kõik valitud',
  'Showing to of records': 'Kuvatud on {{currentPageAmount}} kuni {{currentPage}}/{{total}} kirjest',
  'Google Authenticator description': '<p>Salavõti <strong>Eformi</strong> rakenduse ja <strong>Google Authenticatori</strong> vahel. Hoidke IT-d turvaliselt ja ärge rääkige sellest kellelegi.</p><p> Kui <strong>Google Authenticator</strong> on uuesti installitud või võti eemaldatud, peaksite selle võtme eemaldama</p>',
  'New OTP for': 'Uus OTP jaoks',
  Loading: 'Laadimine',
  Pending: 'Ootel',
  Upload: 'Laadi üles',
  Role: 'Roll',
  'New list': 'Uus nimekiri',
  'Name is required': 'Nimi on kohustuslik',
  'Edit searchable list': 'Muutke otsitavat loendit',
  'eForm Pairing': 'e-vormi sidumine',
  'Tag for removal': 'Silt eemaldamiseks',
  'New tags': 'Uued sildid',
  'Rotate on 90': 'Pöörake 90',
  'Delete picture': 'Kustuta pilt',
  'Open location': 'Ava asukoht',
  'File has been uploaded successfully': 'Fail on edukalt üles laaditud',
  'Error while uploading file': 'Viga faili üleslaadimisel',
  Customers: 'Kliendid',
  'Do you want to leave this page': 'Kas soovite sellelt lehelt lahkuda',
  'If you leave before saving your changes will be lost': 'Kui lahkute enne salvestamist, lähevad muudatused kaotsi',
  'Save & Leave': 'Salvesta ja lahku',
  Cases: 'Juhtumid',
  Calendar: 'Kalender',
  'Case management': 'Juhtumikorraldus',
  Security: 'Turvalisus',
  'Security Groups': 'Turvarühmad',
  GroupName: 'Grupi nimi',
  UserAmount: 'Kasutajate summa',
  'Edit Group': 'Redigeeri gruppi',
  Permissions: 'load',
  'Delete Group': 'Kustuta grupp',
  'New Group': 'Uus grupp',
  'Create Group': 'Loo rühm',
  Group: 'Grupp',
  'Create Security Group': 'Loo turvagrupp',
  'Group Name': 'Grupi nimi',
  'Select User': 'Valige Kasutaja',
  'Bind to Group': 'Seo grupiga',
  Create: 'Loo',
  Close: 'Sulge',
  'Remove user from group': 'Eemalda kasutaja grupist',
  UserManagement: 'Kasutajate haldamine',
  Update: 'Värskenda',
  Read: 'Lugege',
  'General Permissions': 'Üldised load',
  Column: 'Veerg',
  'Eform Permissions': 'Eformi load',
  'Add Eform to Group': 'Lisage Evorm rühma',
  Forbidden: 'Keelatud',
  'Add user to group': 'Lisa kasutaja rühma',
  'Update columns': 'Veergude värskendamine',
  'Download XML': 'Laadige alla XML',
  'Upload ZIP': 'Laadige üles ZIP',
  'Pairing read': 'Sidumislugemine',
  'Pairing update': 'Sidumisvärskendus',
  'Read tags': 'Lugege silte',
  'Update tags': 'Uuenda silte',
  'Get CSV': 'Hankige CSV',
  'Get DOCX': 'Hankige DOCX',
  'Get PDF': 'Hangi PDF',
  'Get PPTX': 'Hankige PPTX',
  'Cases read': 'Juhtumid loetud',
  'Case read': 'Juhtum loetud',
  'Case update': 'Juhtumi värskendus',
  'Case delete': 'Juhtumi kustutamine',
  'Bind Eform': 'Seo Eform',
  'Read Jasper report': 'Lugege Jasperi aruannet',
  'Update Jasper report': 'Värskendage Jasperi aruannet',
  Display: 'Ekraan',
  Enabled: 'Lubatud',
  Disabled: 'Keelatud',
  'Plugins Settings': 'Pluginad',
  PluginsSettings: 'Pluginad',
  'Edit Settings': 'Muuda sätteid',
  'Connection String': 'Ühendusstring',
  Version: 'Versioon',
  'Header Picture': 'Päise pilt',
  Preview: 'Eelvaade',
  Commentary: 'Kommentaar',
  Text: 'Tekst',
  'Selected Value': 'Valitud väärtus',
  'PDF Link': 'PDF link',
  Information: 'Teave',
  Duration: 'Kestus',
  Audio: 'Heli',
  'Are you sure you want to install plugin': 'Kas olete kindel, et soovite installida pistikprogrammi?',
  'This may take up to 10 minutes and rewrite current plugin version if it is already exist in the system': 'Selleks võib kuluda kuni 10 minutit ja praeguse pistikprogrammi versiooni ümberkirjutamine, kui see on süsteemis juba olemas',
  Installation: 'Paigaldamine',
  Install: 'Installige',
  Marketplace: 'Turg',
  'Plugins marketplace': 'Pluginate turg',
  'Plugins Management': 'Pluginate haldus',
  'Warning: you will be logged out when status is changed': 'Hoiatus: oleku muutmisel logitakse teid välja',
  'Select tags for site': 'Valige saidi sildid',
  'Add new': 'Lisa uus',
  'Email Recipients': 'Meili saajad',
  'Manage tags': 'Hallake silte',
  'Add Recipients': 'Lisa adressaadid',
  'Edit email recipient': 'Muuda meili adressaati',
  'Delete email recipient': 'Kustuta meili saaja',
  'No email recipients found': 'Meili saajaid ei leitud',
  'New Email recipients': 'Uued meili saajad',
  'Email addresses': 'Meiliaadressid',
  'Edit tag name': 'Muuda sildi nime',
  'Tags management': 'Siltide haldamine',
  'Email/name text area should have at least one email/name pair': 'E-posti/nime tekstialal peab olema vähemalt üks meili/nime paar',
  'SendGrid Key': 'SendGridi võti',
  'SendGrid Information': 'SendGridi teave',
  'Saved locally': 'Salvestatud kohapeal',
  'Ready for server': 'Serveri jaoks valmis',
  'Received by server': 'Vastu võetud serveri poolt',
  'Retrieved by device': 'Laaditud seadmega',
  Completed: 'Lõpetatud',
  'Case posts': 'Juhtumipostitused',
  'No posts found': 'Ühtegi postitust ei leitud',
  'eForm conducted by': 'e-vormi viis läbi',
  'New post': 'Uus postitus',
  'Created by': 'Loodud',
  To: 'To',
  Subject: 'Teema',
  'Email text': 'e-kirja tekst',
  'Link to case': 'Link juhtumile',
  'Attach PDF': 'Lisage PDF',
  'Post created by': 'Postituse lõi',
  'Post sent to': 'Postitus saadetud aadressile',
  Location: 'Asukoht',
  'InSight Version': 'InSighti versioon',
  'eForm Version': 'e-vormi versioon',
  'Sync delay': 'Sünkroonimise viivitus',
  'Activate sync delay': 'Sünkroonimisviivituse aktiveerimine',
  'Sync dialog': 'Sünkroonimisdialoog',
  'Activate sync dialog': 'Sünkroonimisdialoogi aktiveerimine',
  Push: 'Push',
  'Activate push': 'Aktiveerige tõuge',
  'Move unit': 'Liigutage üksust',
  'Create unit': 'Loo üksus',
  'Select location': 'Valige asukoht',
  Move: 'Liiguta',
  'Current tags': 'Praegused sildid',
  'Edit selectable element': 'Redigeeri valitud elementi',
  'Edit selectable list': 'Redigeeri valitud loendit',
  Folders: 'Kaustad',
  Description: 'Kirjeldus',
  'Create folder': 'Loo kaust',
  'Storage information': 'Salvestusinfo',
  'Swift enabled': 'Swift on lubatud',
  'User name': 'Kasutajanimi',
  'Swift endpoint': 'Kiire lõpp-punkt',
  'Keystone endpoint': 'Keystone lõpp-punkt',
  'S3 enabled': 'S3 lubatud',
  'S3 accessKey': 'S3 juurdepääsuvõti',
  'S3 secretAccessKey': 'S3 SecretAccessKey',
  'S3 endpoint': 'S3 lõpp-punkt',
  'S3 bucket name': 'S3 ämbri nimi',
  'File location picture': 'Pilt faili asukohast',
  'File location PDF': 'Faili asukoht PDF',
  'File location Jasper report files': 'Faili asukoht Jasperi aruandefailid',
  'Http server address': 'HTTP-serveri aadress',
  'SDK information': 'SDK teave',
  'Customer no': 'Kliendi nr',
  Management: 'Juhtimine',
  'Update security group': 'Värskenda turvarühma',
  'eForms permissions': 'e-vormide load',
  'Menu editor': 'Menüüredaktor',
  'Edit menu entry': 'Redigeeri menüükirjet',
  Link: 'Link',
  'Are you sure you want to delete menu entry': 'Kas olete kindel, et soovite menüükirje kustutada?',
  'New dropdown': 'Uus rippmenüü',
  Dropdown: 'Rippmenüü',
  'Security groups': 'Turvagrupid',
  Custom: 'Kohandatud',
  Add: 'Lisama',
  'Generate report': 'Loo {{value}} aruanne',
  'Select date range': 'Valige kuupäevavahemik',
  'Edit headers': 'Muuda päiseid',
  'Text (TextSingleLine)': 'Tekst üherealine',
  Generate: 'Looge suhtlust',
  CheckBox: 'Märkeruut',
  SingleSelect: 'Rippmenüü',
  Comment: 'Tekst',
  Number: 'Numbriline',
  Picture: 'Pilt',
  None: 'Infokast',
  Date: 'Kuupäev',
  SaveButton: 'Salvesta',
  ShowPDF: 'PDF',
  FieldGroup: 'Grupp',
  NumberStepper: 'Stepper',
  Signature: 'Allkiri',
  Timer: 'StartStopTime',
  EntitySearch: 'Rippmenüü otsing (minu loendid)',
  EntitySelect: 'Rippmenüü (minu loendid)',
  MultiSelect: 'Mitmikvalimine',
  'Save eForm.': 'Salvesta e-vorm',
  'Server date': 'Serveri kuupäev',
  'eForm Pairing for': 'e-vormi sidumine',
  'Select Site': 'Valige Sait',
  Select: 'Valige',
  Employee: 'Töötaja',
  Site: 'Sait',
  'Selected language': 'Valitud keel',
  'New folder': 'Uus kaust',
  Back: 'tagasi',
  Danish: 'taani keel',
  English: 'Inglise',
  German: 'saksa keel',
  Spanish: 'hispaania keel',
  French: 'prantsuse keel',
  Italian: 'itaalia keel',
  Polish: 'poola keel',
  Portuguese: 'portugali keel',
  'Brazilian Portuguese': 'Brasiilia portugali keel',
  Ukrainian: 'ukrainlane',
  Swedish: 'rootsi keel',
  Norwegian: 'norra keel',
  Dutch: 'hollandi keel',
  Finish: 'Lõpetama',
  'Done at': 'Tehtud kell',
  'Select date': 'Valige kuupäev',
  Value: 'Väärtus',
  'Please fill out this field': 'Palun täitke see väli',
  'Submitted date': 'Esitamise kuupäev',
  Languages: 'Keeled',
  'Uploading progress:': 'Üleslaadimise edenemine:',
  'Choose file': 'Vali fail',
  'PAGINATOR.ITEMS_PER_PAGE': 'Üksusi lehel',
  'PAGINATOR.NEXT_PAGE': 'Järgmine leht',
  'PAGINATOR.PREVIOUS_PAGE': 'Eelmine leht',
  'PAGINATOR.OF_LABEL': 'kohta',
  'PAGINATOR.RANGE_PAGE_LABEL': 'kohta',
  'PAGINATOR.PAGE': 'Lehekülg',
  'No data in selected period': 'Valitud perioodil andmeid pole',
  'Dark theme': 'Tume teema',
  Formats: 'Vormingud',
  'Save settings': 'Salvesta sätted',
  'No records found': 'Kirjeid ei leitud',
  'Time zone': 'Ajavöönd',
  'Set password': 'Määra parool',
  'Edit user': 'Muuda kasutajat',
  'Create user': 'Loo kasutaja',
  'Done button enabled': 'Valmis nupp on lubatud',
};
