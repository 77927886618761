export const elGR = {
  Settings: 'Ρυθμίσεις',
  Save: 'Αποθηκεύσετε',
  'Login Page': 'Σελίδα σύνδεσης',
  Reset: 'Επαναφορά',
  'Main Text': 'Κύριο κείμενο',
  'Secondary Text': 'Δευτερεύον κείμενο',
  Image: 'Εικόνα',
  'Recommended parameters': 'Προτεινόμενες παράμετροι',
  Resolution: 'Ανάλυση',
  Proportions: 'Αναλογίες',
  'Site header': 'Επικεφαλίδα ιστότοπου',
  Logo: 'Λογότυπο',
  'SMTP Information': 'Πληροφορίες SMTP',
  Host: 'Πλήθος',
  Port: 'Λιμάνι',
  Login: 'Σύνδεση',
  Password: 'Κωδικός πρόσβασης',
  'Forgot password': 'Ξεχάσατε τον κωδικό',
  'Restore password': 'Επαναφορά κωδικού πρόσβασης',
  'Reset admin password': 'Επαναφορά κωδικού πρόσβασης διαχειριστή',
  'Back to login': 'Επιστροφή στην σελίδα εισόδου',
  'Reset password': 'Επαναφέρετε τον κωδικό πρόσβασης',
  Approved: 'Εγκρίθηκε',
  Review: 'Ανασκόπηση',
  'Not approved': 'Δεν εγκρινεται',
  'Updating cases, please wait': 'Ενημέρωση υποθέσεων, περιμένετε',
  Search: 'Αναζήτηση',
  done_at: 'Η ηλεκτρονική φόρμα ολοκληρώθηκε στις',
  created_at: 'Προσβάσιμα δεδομένα στο',
  worker_name: 'Η ηλεκτρονική φόρμα έγινε από',
  'Edit Case': 'Επεξεργασία υπόθεσης',
  'Delete Case': 'Διαγραφή υπόθεσης',
  Download: 'Κατεβάστε',
  'Are you sure you want to delete this case': 'Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτήν την υπόθεση',
  'Accessible at': 'Προσβάσιμο στο',
  'Finished at': 'Ολοκληρώθηκε στις',
  'Done by': 'Γίνεται από',
  OK: 'Εντάξει',
  Cancel: 'Ματαίωση',
  'Edit Searchable item': 'Επεξεργασία στοιχείου με δυνατότητα αναζήτησης',
  'Delete Searchable item': 'Διαγραφή στοιχείου με δυνατότητα αναζήτησης',
  'Edit searchable element': 'Επεξεργασία στοιχείου με δυνατότητα αναζήτησης',
  Name: 'Ονομα',
  Ok: 'Εντάξει',
  'Import options (max 100 entries)': 'Επιλογές εισαγωγής (έως 100 καταχωρήσεις)',
  Import: 'Εισαγωγή',
  'My eForms': 'Οι ηλεκτρονικές μου φόρμες',
  'Create eForm': 'Δημιουργία eForm',
  'New eForm': 'Νέα ηλεκτρονική φόρμα',
  'New eForm from XML': 'Νέα ηλεκτρονική φόρμα από XML',
  Label: 'Επιγραφή',
  Tags: 'Ετικέτες',
  CreatedAt: 'Δημιουργήθηκε στο',
  'eForm Name': 'Όνομα eForm',
  Pairing: 'Σύζευξη',
  Actions: 'Ενέργειες',
  'Edit tags': 'Επεξεργασία ετικετών',
  'Click to edit eForm pairing with user(s)': 'Κάντε κλικ για να επεξεργαστείτε τη σύζευξη eForm με τους χρήστες',
  'Edit pairing': 'Επεξεργασία σύζευξης',
  'Click to pair eForm with user(s)': 'Κάντε κλικ για σύζευξη eForm με χρήστες',
  'Pair eForm': 'Σύζευξη eForm',
  'Show cases': 'Εμφάνιση περιπτώσεων',
  'Edit columns': 'Επεξεργασία στηλών',
  'Download CSV': 'Λήψη CSV',
  'Delete eForm': 'Διαγραφή eForm',
  'Upload ZIP archive': 'Μεταφόρτωση αρχείου ZIP',
  'Upload template ZIP': 'Μεταφόρτωση προτύπου ZIP',
  'Uploading progress': 'Πρόοδος μεταφόρτωσης',
  'Save eForm': 'Αποθήκευση eForm',
  'Device user': 'Χρήστης συσκευής',
  'Check to pair': 'Ελέγξτε για σύζευξη',
  'Save pairing': 'Αποθήκευση σύζευξης',
  'Select columns for displaying in Cases': 'Επιλέξτε στήλες για εμφάνιση σε Θήκες',
  Field: 'Πεδίο',
  'Are you sure you want to delete': 'Είστε βέβαιοι ότι θέλετε να διαγράψετε',
  'Creation Date': 'Ημερομηνία δημιουργίας',
  'Select tags for template': 'Επιλέξτε ετικέτες για το πρότυπο',
  'Template tags': 'Ετικέτες προτύπων',
  'New tag': 'Νέα ετικέτα',
  'Tag list': 'Λίστα ετικετών',
  'Add field': 'Προσθήκη πεδίου',
  'Add sub eForm': 'Προσθήκη δευτερεύουσας φόρμας',
  'Edit sub eForm': 'Επεξεργασία δευτερεύουσας φόρμας',
  'New sub eForm': 'Νέα δευτερεύουσα φόρμα',
  'New field': 'Νέο πεδίο',
  'Field is not complete': 'Το πεδίο δεν είναι πλήρες',
  'Add new nested field': 'Προσθήκη πεδίου στην ομάδα',
  'Copy field': 'Πεδίο αντιγραφής',
  'Edit field': 'Επεξεργασία πεδίου',
  'Delete field': 'Διαγραφή πεδίου',
  'Expand field': 'Εμφάνιση πεδίων στην ομάδα',
  'Collapse field': 'Απόκρυψη πεδίων στην ομάδα',
  Mandatory: 'Επιτακτικός',
  Type: 'Τύπος',
  'Are you sure you want to delete field': 'Είστε βέβαιοι ότι θέλετε να διαγράψετε το πεδίο',
  'eForm is not complete': 'Το eForm δεν είναι πλήρες',
  'Edit eForm': 'Επεξεργασία eForm',
  'New nested field': 'Νέο ένθετο πεδίο',
  Delete: 'Διαγράφω',
  'XML from eForm Builder': 'XML από το eForm Builder',
  'Paste XML here': 'Επικολλήστε εδώ XML',
  'Creation date': 'Ημερομηνία δημιουργίας',
  'Searchable lists': 'Λίστες με δυνατότητα αναζήτησης',
  'Searchable list': 'Λίστα με δυνατότητα αναζήτησης',
  'Create new searchable list': 'Δημιουργία νέας λίστας με δυνατότητα αναζήτησης',
  'Edit site': 'Επεξεργασία ιστότοπου',
  'Selectable lists': 'Επιλέξιμες λίστες',
  'Selectable list': 'Επιλογή λίστας',
  'Create new selectable list': 'Δημιουργία νέας λίστας με δυνατότητα επιλογής',
  'Terms of Service': 'Όροι χρήσης',
  'Privacy Policy': 'Πολιτική Απορρήτου',
  'Data Protection Terms (GDPR)': 'Όροι προστασίας δεδομένων (GDPR)',
  'Save password': 'Αποθήκευση κωδικού',
  'Edit Device User': 'Επεξεργασία χρήστη συσκευής',
  'First name': 'Ονομα',
  'Last name': 'Επίθετο',
  'Device Users': 'Χρήστες συσκευών',
  'Create Device User': 'Δημιουργία χρήστη συσκευής',
  'New Device User': 'Νέος χρήστης συσκευής',
  'Site ID': 'ID τοποθεσίας',
  'Device ID': 'Αναγνωριστικό συσκευής',
  Customer: 'Πελάτης',
  'Customer no & OTP': 'Αριθμός πελάτη &amp; OTP',
  'New OTP': 'Νέο OTP',
  'Delete Device User': 'Διαγραφή χρήστη συσκευής',
  WARNING: 'ΠΡΟΕΙΔΟΠΟΙΗΣΗ',
  'When pressing OK, you will deactivate the current device paired with this location': 'Όταν πατήσετε OK, θα απενεργοποιήσετε την τρέχουσα συσκευή που έχει αντιστοιχιστεί με αυτήν τη θέση',
  'If there is data on the current device, this data will be lost after pressing OK': 'Εάν υπάρχουν δεδομένα στην τρέχουσα συσκευή, αυτά τα δεδομένα θα χαθούν αφού πατήσετε OK',
  Edit: 'Επεξεργασία',
  Sites: 'Τοποθεσίες',
  'Updated at': 'Ενημερώθηκε στις',
  'Are you sure you want to delete the site? The action can not be undone': 'Είστε βέβαιοι ότι θέλετε να διαγράψετε τον ιστότοπο; Η ενέργεια δεν μπορεί να αναιρεθεί',
  Previous: 'Προηγούμενος',
  Next: 'Επόμενο',
  Units: 'Μονάδες',
  'Get OTP code': 'Λάβετε κωδικό OTP',
  'Activate OTP code for': 'Ενεργοποιήστε τον κωδικό OTP για',
  'When pressing OK, you will deactivate the current unit assosiated with this location': 'Όταν πατήσετε OK, θα απενεργοποιήσετε την τρέχουσα μονάδα που σχετίζεται με αυτήν τη θέση',
  'You will se the new OTP code once you click OK': 'Θα δείτε τον νέο κωδικό OTP μόλις κάνετε κλικ στο OK',
  Workers: 'Εργάτες',
  'Create Worker': 'Δημιουργία Εργάτη',
  'Edit Worker': 'Edit Worker',
  'Delete Worker': 'Διαγραφή Εργάτη',
  'Are you sure you want to delete the worker': 'Είστε βέβαιοι ότι θέλετε να διαγράψετε τον εργαζόμενο',
  'Select Worker': 'Επιλέξτε Εργάτης',
  'Save Worker': 'Save Worker',
  Advanced: 'διαχειριστής',
  'Application Settings': 'Ρυθμίσεις εφαρμογής',
  'User management': 'Διαχείριση χρηστών',
  'Change password': 'Αλλαξε κωδικό',
  Logout: 'Αποσύνδεση',
  'Profile settings': 'Ρυθμίσεις προφίλ',
  Timezone: 'Ζώνη ώρας',
  Language: 'Γλώσσα',
  'Force users to use 2-f-auth': 'Αναγκάστε τους χρήστες να χρησιμοποιήσουν το 2-f-auth',
  'Old password': 'ΠΑΛΙΟΣ ΚΩΔΙΚΟΣ',
  'New password': 'Νέος κωδικός πρόσβασης (ελάχ. 6 χαρακτήρες)',
  'New password confirmation': 'Επιβεβαίωση νέου κωδικού πρόσβασης (ελάχ. 6 χαρακτήρες)',
  'Confirm new password': 'Επιβεβαίωση νέου κωδικού πρόσβασης (τουλάχιστον 6 χαρακτήρες)',
  'Enter new password': 'Εισαγάγετε νέο κωδικό πρόσβασης (τουλάχιστον 6 χαρακτήρες)',
  'Enter old password': 'Εισαγάγετε τον παλιό κωδικό πρόσβασης',
  'New User': 'Νέος χρήστης',
  'Edit User': 'Επεξεργασία χρήστη',
  'Left empty if want to keep old': 'Αφήστε κενό εάν θέλετε να διατηρήσετε παλιά',
  Username: 'Όνομα χρήστη',
  Email: 'ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ',
  Status: 'Κατάσταση',
  'change will logout you from the application': 'αλλαγή θα σας αποσυνδέσει από την εφαρμογή',
  'Remove google authenticator secret key': 'Καταργήστε το μυστικό κλειδί του google authenticator',
  'Remove PSK': 'Αφαιρέστε το PSK',
  'Full Name': 'Πλήρες όνομα',
  'Delete user': 'Διαγραφή χρήστη',
  'Open Google Authenticator and add Eform application by scanning the QR Code to the right': 'Ανοίξτε τον Επαληθευτή Google και προσθέστε την εφαρμογή Eform σαρώνοντας τον κωδικό QR στα δεξιά',
  'Enter the 6 digit code that Google Authenticator generates': 'Εισαγάγετε τον 6ψήφιο κωδικό που δημιουργεί το Google Authenticator',
  General: 'Γενικός',
  Find: 'Εύρημα',
  'Nothing found': 'Δεν βρέθηκε τίποτα',
  'Type in search box to see results': 'Πληκτρολογήστε στο πλαίσιο αναζήτησης για να δείτε αποτελέσματα',
  'Select tags for filter': 'Επιλέξτε ετικέτες για φίλτρο',
  'All selected': 'Όλα επιλεγμένα',
  'Showing to of records': 'Εμφάνιση εγγραφών {{currentPageAmount}} έως {{currentPage}} από {{total}}',
  'Google Authenticator description': '<p>Μυστικό κλειδί μεταξύ της εφαρμογής <strong>Eform</strong> και <strong>του Google Authenticator</strong> . Διατηρήστε το ΙΤ ασφαλές και μην το πείτε σε κανέναν.</p><p> Θα πρέπει να καταργήσετε αυτό το κλειδί, εάν <strong>ο Επαληθευτής Google</strong> εγκατασταθεί ξανά ή το κλειδί αφαιρέθηκε</p>',
  'New OTP for': 'Νέο OTP για',
  Loading: 'Φόρτωση',
  Pending: 'εκκρεμής',
  Upload: 'Μεταφόρτωση',
  Role: 'Ρόλος',
  'New list': 'Νέα λίστα',
  'Name is required': 'Το όνομα απαιτείται',
  'Edit searchable list': 'Επεξεργασία λίστας με δυνατότητα αναζήτησης',
  'eForm Pairing': 'Σύζευξη eForm',
  'Tag for removal': 'Ετικέτα για αφαίρεση',
  'New tags': 'Νέες ετικέτες',
  'Rotate on 90': 'Περιστροφή στο 90',
  'Delete picture': 'Διαγραφή εικόνας',
  'Open location': 'Ανοιχτή τοποθεσία',
  'File has been uploaded successfully': 'Το αρχείο μεταφορτώθηκε με επιτυχία',
  'Error while uploading file': 'Σφάλμα κατά τη μεταφόρτωση του αρχείου',
  Customers: 'Οι πελάτες',
  'Do you want to leave this page': 'Θέλετε να φύγετε από αυτή τη σελίδα',
  'If you leave before saving your changes will be lost': 'Εάν φύγετε πριν από την αποθήκευση, οι αλλαγές σας θα χαθούν',
  'Save & Leave': 'Αποθήκευση &amp; Αποχώρηση',
  Cases: 'Υποθέσεις',
  Calendar: 'Ημερολόγιο',
  'Case management': 'Διαχείριση υπόθεσης',
  Security: 'Ασφάλεια',
  'Security Groups': 'Ομάδες Ασφαλείας',
  GroupName: 'Ονομα ομάδας',
  UserAmount: 'Ποσό χρηστών',
  'Edit Group': 'Επεξεργασία ομάδας',
  Permissions: 'Άδειες',
  'Delete Group': 'Διαγραφή ομάδας',
  'New Group': 'Νέα ομάδα',
  'Create Group': 'Δημιουργησε ΟΜΑΔΑ',
  Group: 'Ομάδα',
  'Create Security Group': 'Δημιουργία ομάδας ασφαλείας',
  'Group Name': 'Ονομα ομάδας',
  'Select User': 'Επιλέξτε Χρήστης',
  'Bind to Group': 'Σύνδεση στην ομάδα',
  Create: 'Δημιουργώ',
  Close: 'Κλείσε',
  'Remove user from group': 'Κατάργηση χρήστη από την ομάδα',
  UserManagement: 'Διαχείριση χρηστών',
  Update: 'Εκσυγχρονίζω',
  Read: 'Ανάγνωση',
  'General Permissions': 'Γενικές Άδειες',
  Column: 'Στήλη',
  'Eform Permissions': 'Δικαιώματα Eform',
  'Add Eform to Group': 'Προσθήκη Eform στην ομάδα',
  Forbidden: 'Απαγορευμένος',
  'Add user to group': 'Προσθήκη χρήστη στην ομάδα',
  'Update columns': 'Ενημέρωση στηλών',
  'Download XML': 'Λήψη XML',
  'Upload ZIP': 'Μεταφόρτωση ZIP',
  'Pairing read': 'Ανάγνωση σύζευξης',
  'Pairing update': 'Ενημέρωση ζεύξης',
  'Read tags': 'Διαβάστε ετικέτες',
  'Update tags': 'Ενημέρωση ετικετών',
  'Get CSV': 'Λήψη CSV',
  'Get DOCX': 'Αποκτήστε το DOCX',
  'Get PDF': 'Λήψη PDF',
  'Get PPTX': 'Λάβετε PPTX',
  'Cases read': 'Διαβάζονται περιπτώσεις',
  'Case read': 'Διαβάστηκε η υπόθεση',
  'Case update': 'Ενημέρωση υπόθεσης',
  'Case delete': 'Διαγραφή υπόθεσης',
  'Bind Eform': 'Δέστε Eform',
  'Read Jasper report': 'Διαβάστε την έκθεση Jasper',
  'Update Jasper report': 'Ενημέρωση αναφοράς Jasper',
  Display: 'Απεικόνιση',
  Enabled: 'Ενεργοποιήθηκε',
  Disabled: 'άτομα με ειδικές ανάγκες',
  'Plugins Settings': 'Πρόσθετα',
  PluginsSettings: 'Πρόσθετα',
  'Edit Settings': 'Επεξεργασία ρυθμίσεων',
  'Connection String': 'Συμβολοσειρά σύνδεσης',
  Version: 'Εκδοχή',
  'Header Picture': 'Εικόνα κεφαλίδας',
  Preview: 'Προεπισκόπηση',
  Commentary: 'Σχολιασμός',
  Text: 'Κείμενο',
  'Selected Value': 'Επιλεγμένη τιμή',
  'PDF Link': 'Σύνδεσμος PDF',
  Information: 'Πληροφορίες',
  Duration: 'Διάρκεια',
  Audio: 'Ήχος',
  'Are you sure you want to install plugin': 'Είστε βέβαιοι ότι θέλετε να εγκαταστήσετε την προσθήκη',
  'This may take up to 10 minutes and rewrite current plugin version if it is already exist in the system': 'Αυτό μπορεί να διαρκέσει έως και 10 λεπτά και να ξαναγράψει την τρέχουσα έκδοση προσθήκης, εάν υπάρχει ήδη στο σύστημα',
  Installation: 'Εγκατάσταση',
  Install: 'Εγκαθιστώ',
  Marketplace: 'Αγορά',
  'Plugins marketplace': 'Αγορά προσθηκών',
  'Plugins Management': 'Διαχείριση προσθηκών',
  'Warning: you will be logged out when status is changed': 'Προειδοποίηση: θα αποσυνδεθείτε όταν αλλάξει η κατάσταση',
  'Select tags for site': 'Επιλέξτε ετικέτες για τον ιστότοπο',
  'Add new': 'Προσθεσε νεο',
  'Email Recipients': 'Παραλήπτες email',
  'Manage tags': 'Διαχείριση ετικετών',
  'Add Recipients': 'Προσθήκη παραληπτών',
  'Edit email recipient': 'Επεξεργασία παραλήπτη email',
  'Delete email recipient': 'Διαγραφή παραλήπτη email',
  'No email recipients found': 'Δεν βρέθηκαν παραλήπτες email',
  'New Email recipients': 'Νέοι παραλήπτες email',
  'Email addresses': 'Διευθύνσεις ηλεκτρονικού ταχυδρομείου',
  'Edit tag name': 'Επεξεργασία ονόματος ετικέτας',
  'Tags management': 'Διαχείριση ετικετών',
  'Email/name text area should have at least one email/name pair': 'Η περιοχή κειμένου email/όνομα πρέπει να έχει τουλάχιστον ένα ζεύγος email/όνομα',
  'SendGrid Key': 'Κλειδί SendGrid',
  'SendGrid Information': 'Πληροφορίες SendGrid',
  'Saved locally': 'Αποθηκεύτηκε τοπικά',
  'Ready for server': 'Έτοιμο για διακομιστή',
  'Received by server': 'Λήψη από διακομιστή',
  'Retrieved by device': 'Ανακτήθηκε από συσκευή',
  Completed: 'Ολοκληρώθηκε το',
  'Case posts': 'Αναρτήσεις υποθέσεων',
  'No posts found': 'Δεν βρέθηκαν αναρτήσεις',
  'eForm conducted by': 'Ηλεκτρονική Φόρμα που διεξάγεται από',
  'New post': 'Νέα ανάρτηση',
  'Created by': 'Δημιουργήθηκε από',
  To: 'Προς την',
  Subject: 'Θέμα',
  'Email text': 'κείμενο ηλεκτρονικού ταχυδρομείου',
  'Link to case': 'Σύνδεσμος με υπόθεση',
  'Attach PDF': 'Επισυνάψτε PDF',
  'Post created by': 'Η ανάρτηση δημιουργήθηκε από',
  'Post sent to': 'Η ανάρτηση στάλθηκε σε',
  Location: 'Τοποθεσία',
  'InSight Version': 'Έκδοση InSight',
  'eForm Version': 'Έκδοση eForm',
  'Sync delay': 'Καθυστέρηση συγχρονισμού',
  'Activate sync delay': 'Ενεργοποίηση καθυστέρησης συγχρονισμού',
  'Sync dialog': 'Διαλόγου συγχρονισμού',
  'Activate sync dialog': 'Ενεργοποίηση διαλόγου συγχρονισμού',
  Push: 'Σπρώξτε',
  'Activate push': 'Ενεργοποιήστε την ώθηση',
  'Move unit': 'Μετακίνηση μονάδας',
  'Create unit': 'Δημιουργία μονάδας',
  'Select location': 'Επιλέξτε τοποθεσία',
  Move: 'Κίνηση',
  'Current tags': 'Τρέχουσες ετικέτες',
  'Edit selectable element': 'Επεξεργασία επιλέξιμου στοιχείου',
  'Edit selectable list': 'Επεξεργασία λίστας με δυνατότητα επιλογής',
  Folders: 'Φάκελοι',
  Description: 'Περιγραφή',
  'Create folder': 'ΔΗΜΙΟΥΡΓΩ φακελο',
  'Storage information': 'Πληροφορίες αποθήκευσης',
  'Swift enabled': 'Το Swift ενεργοποιήθηκε',
  'User name': 'Ονομα χρήστη',
  'Swift endpoint': 'Γρήγορο τελικό σημείο',
  'Keystone endpoint': 'Τελικό σημείο Keystone',
  'S3 enabled': 'S3 ενεργοποιημένο',
  'S3 accessKey': 'Κλειδί πρόσβασης S3',
  'S3 secretAccessKey': 'S3 SecretAccessKey',
  'S3 endpoint': 'S3 τελικό σημείο',
  'S3 bucket name': 'Όνομα κάδου S3',
  'File location picture': 'Εικόνα τοποθεσίας αρχείου',
  'File location PDF': 'Τοποθεσία αρχείου PDF',
  'File location Jasper report files': 'Τοποθεσία αρχείου Αρχεία αναφοράς Jasper',
  'Http server address': 'Διεύθυνση διακομιστή Http',
  'SDK information': 'Πληροφορίες SDK',
  'Customer no': 'Αρ. πελάτη',
  Management: 'Διαχείριση',
  'Update security group': 'Ενημέρωση ομάδας ασφαλείας',
  'eForms permissions': 'Δικαιώματα eForms',
  'Menu editor': 'Επεξεργαστής μενού',
  'Edit menu entry': 'Επεξεργασία καταχώρισης μενού',
  Link: 'Σύνδεσμος',
  'Are you sure you want to delete menu entry': 'Είστε βέβαιοι ότι θέλετε να διαγράψετε την καταχώριση μενού',
  'New dropdown': 'Νέο αναπτυσσόμενο μενού',
  Dropdown: 'Αναπτυσσόμενο',
  'Security groups': 'Ομάδες ασφαλείας',
  Custom: 'Εθιμο',
  Add: 'Προσθήκη',
  'Generate report': 'Δημιουργία αναφοράς {{value}}',
  'Select date range': 'Επιλέξτε εύρος ημερομηνιών',
  'Edit headers': 'Επεξεργασία κεφαλίδων',
  'Text (TextSingleLine)': 'Κείμενο μονής γραμμής',
  Generate: 'Δημιουργήστε σχέσεις',
  CheckBox: 'Πλαίσιο ελέγχου',
  SingleSelect: 'Αναπτυσσόμενο',
  Comment: 'Κείμενο',
  Number: 'Αριθμητικός',
  Picture: 'Εικόνα',
  None: 'Infobox',
  Date: 'Ημερομηνία',
  SaveButton: 'Αποθηκεύσετε',
  ShowPDF: 'PDF',
  FieldGroup: 'Ομάδα',
  NumberStepper: 'Πατών',
  Signature: 'Υπογραφή',
  Timer: 'StartStopTime',
  EntitySearch: 'Αναπτυσσόμενη αναζήτηση (οι λίστες μου)',
  EntitySelect: 'Αναπτυσσόμενο μενού (οι λίστες μου)',
  MultiSelect: 'Πολλαπλή επιλογή',
  'Save eForm.': 'Αποθήκευση eForm',
  'Server date': 'Ημερομηνία διακομιστή',
  'eForm Pairing for': 'eForm Pairing για',
  'Select Site': 'Επιλέξτε Ιστότοπος',
  Select: 'Επιλέγω',
  Employee: 'Υπάλληλος',
  Site: 'Ιστοσελίδα',
  'Selected language': 'Επιλεγμένη γλώσσα',
  'New folder': 'Νέος φάκελος',
  Back: 'Πίσω',
  Danish: 'δανικός',
  English: 'Αγγλικά',
  German: 'Γερμανός',
  Spanish: 'Ισπανικά',
  French: 'γαλλική γλώσσα',
  Italian: 'ιταλικός',
  Polish: 'Στίλβωση',
  Portuguese: 'Πορτογαλικά',
  'Brazilian Portuguese': 'Πορτογάλοι Βραζιλίας',
  Ukrainian: 'Ουκρανός',
  Swedish: 'Σουηδικά',
  Norwegian: 'Νορβηγός',
  Dutch: 'Ολλανδός',
  Finish: 'Φινίρισμα',
  'Done at': 'Έγινε στις',
  'Select date': 'Επιλέξτε ημερομηνία',
  Value: 'αξία',
  'Please fill out this field': 'Παρακαλώ συμπληρώστε αυτό το πεδίο',
  'Submitted date': 'Ημερομηνία υποβολής',
  Languages: 'Γλώσσες',
  'Uploading progress:': 'Πρόοδος μεταφόρτωσης:',
  'Choose file': 'Επιλέξτε το αρχείο',
  'PAGINATOR.ITEMS_PER_PAGE': 'αντικείμενα ανά σελίδα',
  'PAGINATOR.NEXT_PAGE': 'Επόμενη σελίδα',
  'PAGINATOR.PREVIOUS_PAGE': 'Προηγούμενη σελίδα',
  'PAGINATOR.OF_LABEL': 'του',
  'PAGINATOR.RANGE_PAGE_LABEL': 'του',
  'PAGINATOR.PAGE': 'Σελίδα',
  'No data in selected period': 'Δεν υπάρχουν δεδομένα στην επιλεγμένη περίοδο',
  'Dark theme': 'Σκοτεινό θέμα',
  Formats: 'Μορφές',
  'Save settings': 'Αποθήκευση ρυθμίσεων',
  'No records found': 'Δεν βρέθηκαν καταγραφές',
  'Time zone': 'Ζώνη ώρας',
  'Set password': 'Ορίστε κωδικό',
  'Edit user': 'Επεξεργασία χρήστη',
  'Create user': 'Δημιουργία χρήστη',
  'Done button enabled': 'Ενεργοποιήθηκε το κουμπί Τέλος',
};
