<div class="h-100 d-flex flex-column" *ngIf="selectIsAuth$ | async">
  <!--  <app-header></app-header>-->
  <mat-toolbar style="padding-top: 5px; padding-bottom: 5px" class="d-flex align-items-center">
    <div *ngIf="headerSettingsModel.imageLinkVisible" class="d-flex">
      <div class="logo-container">
        <img height="30px" id="site-header-image" [src]="logoImage | authImage | async"
             *ngIf="headerSettingsModel.imageLinkVisible" alt="">
      </div>
    </div>
    <button mat-icon-button (click)="toggleDrawer()" [hidden]="(selectConnectionStringExists$ | async) === false">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="spacer"></span>
    <div class="tag">
      <mat-icon>account_circle</mat-icon>
      <button mat-icon-button [matMenuTriggerFor]="menu" id="sign-out-dropdown">
        <mat-icon style="padding-top: 2px !important; padding-left: 5px !important;">settings</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <a mat-menu-item *ngFor="let menu of allAppMenus$ | async"
           routerLink="{{ menu.link }}"
           [id]="menu.e2EId"
        ><!--color="{{this.router.url === menu.link ? 'accent' : ''}}"-->
          {{ menu.name }}
        </a>
      </mat-menu>
    </div>
  </mat-toolbar>
  <mat-drawer-container autosize style="height: 100%;">
    <mat-drawer
      #drawer
      [mode]="sidenavMode"
      [opened]="(selectSideMenuOpened$ | async) === true">
      <app-navigation (clickOnLink)="onClickOnLink()"></app-navigation>
      <app-footer></app-footer>
    </mat-drawer>
    <div class="mb-auto p-4" *ngIf="selectIsAuth$ | async">
      <app-userback-widget></app-userback-widget>
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>
</div>
